<template>
  <div>
    <template v-if="receiptDetail != null">
      <!-- Invoice Start -->
      <section class="bg-light">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-10 no-print">
              <!-- toolbar -->
              <div class="d-flex justify-content-end">
                <b-button variant="primary" @click="printInvoice"
                  >Print Receipt</b-button
                >
              </div>
              <!-- end toolbar -->
            </div>
            <div class="col-lg-10">
              <div class="card shadow rounded border-0">
                <div class="card-body">
                  <div class="invoice-top pb-4 border-bottom">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="logo-invoice mb-2">Receipt</div>
                      </div>
                      <!--end col-->

                      <div class="col-md-4 mt-4 mt-sm-0">
                        <h5>DekNerd Co., Ltd.</h5>
                        <address>
                          <strong>Address: </strong>
                          144/14-15 room no. 102<br />
                          Silom 10 rd., Suriyahwong, Bang Ruk, Bangkok 10500
                          <br />
                        </address>
                        <strong>Tax ID.</strong> 0105560163073
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </div>

                  <div class="invoice-middle py-4">
                    <h5>Receipt Details :</h5>
                    <div class="row mb-0 no-gutters">
                      <div class="col-md-8 order-2 order-md-1">
                        <dl class="row no-gutters">
                          <dt class="col-md-3 col-3 font-weight-normal">
                            Invoice No. :
                          </dt>
                          <dd class="col-md-9 col-9 text-muted">
                            {{ receiptDetail.invoiceNo }}
                          </dd>

                          <dt class="col-md-3 col-3 font-weight-normal">
                            Customer Name :
                          </dt>
                          <dd class="col-md-9 col-9 text-muted">
                            {{ receiptDetail.customerName }}
                          </dd>

                          <!-- <dt class="col-md-3 col-5 font-weight-normal">
                          Address :
                        </dt>
                        <dd class="col-md-9 col-7 text-muted">
                          <p class="mb-0">1962 Pike Street,</p>
                          <p>Diego, CA 92123</p>
                        </dd>

                        <dt class="col-md-3 col-5 font-weight-normal">
                          Phone :
                        </dt>
                        <dd class="col-md-9 col-7 text-muted">
                          (+45) 4584-458-695
                        </dd> -->
                        </dl>
                      </div>

                      <div class="col-md-4 order-md-2 order-1 mt-2 mt-sm-0">
                        <dl class="row mb-0 no-gutters">
                          <dt class="col-md-3 col-5 font-weight-normal">
                            Date :
                          </dt>
                          <dd class="col-md-9 col-7 text-muted">
                            {{
                              receiptDetail.invoiceDate
                                | moment("DD MMM YYYY HH:mm:ss")
                            }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>

                  <div class="invoice-table pb-4">
                    <div class="table-responsive bg-white shadow rounded">
                      <table class="table mb-0 table-center invoice-tb">
                        <thead class="bg-light">
                          <tr>
                            <th scope="col" class="text-left">No.</th>
                            <th scope="col" class="text-left">Item</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Amount (THB)</th>
                            <th scope="col">Total (THB)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-for="item of receiptDetail.items">
                            <tr :key="`item_${item.no}`">
                              <th scope="row" class="text-left">
                                {{ item.no }}
                              </th>
                              <td class="text-left">{{ item.item }}</td>
                              <td>{{ item.qty }}</td>
                              <td>{{ item.amount | numFormat("0,0.00") }}</td>
                              <td>{{ item.total | numFormat("0,0.00") }}</td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>

                    <div class="row">
                      <div class="col-lg-4 col-md-5 ml-auto">
                        <ul
                          class="list-unstyled h5 font-weight-normal mt-4 mb-0"
                        >
                          <li class="text-muted d-flex justify-content-between">
                            Subtotal :<span
                              >{{
                                receiptDetail.subtotal | numFormat("0,0.00")
                              }}
                              THB</span
                            >
                          </li>
                          <li class="text-muted d-flex justify-content-between">
                            Taxes (7%) :<span>
                              {{ receiptDetail.tax | numFormat("0,0.00") }} THB
                            </span>
                          </li>
                          <li class="d-flex justify-content-between">
                            Total :<span
                              >{{
                                receiptDetail.total | numFormat("0,0.00")
                              }}
                              THB</span
                            >
                          </li>
                        </ul>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </div>

                  <div class="invoice-footer border-top pt-4">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="text-sm-left text-muted text-center">
                          <h6 class="mb-0">Customer Services : 022380209</h6>
                        </div>
                      </div>

                      <div class="col-sm-6"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </section>
      <!--end section-->
      <!-- Invoice End -->
    </template>
    <template v-else>
      <div class="text-center p-5">
        <b-spinner
          variant="primary"
          style="width: 3rem; height: 3rem"
        ></b-spinner>

        <div class="mt-4">
          <h4>Loading Receipt Detail</h4>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { EventBus } from "../../../event-bus";
import { getReceipt } from "../../../services/omiseService";
export default {
  name: "Receipt",
  beforeDestroy() {
    // EventBus.$off("onLayoutChange");
  },
  mounted() {
    this.init();
  },
  data: () => ({
    receiptDetail: null,
  }),
  methods: {
    printInvoice() {
      window.print();
    },
    init() {
      getReceipt(this.$route.params.id)
        .then((resp) => {
          this.receiptDetail = resp.data;
        })
        .catch((err) => {
          alert("Can not get receipt information");
        });
    },
  },
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>